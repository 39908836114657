<script>
/**
 * Configs Data component
 */
import EditModal from "./update-config.vue";
import { authComputed, configAlertsMethods } from "@/state/helpers";
export default {
  props: ["configs", "busy", "loaderStatus"],
  components: {
    EditModal,
  },
  data() {
    return {
      config: {},
      configsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            key: "device",
            sortable: false,
            label: "Serial",
          },
          {
            key: "condition",
            sortable: false,
            label: this.$t("dataTable.condition.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
          {
            key: "minutesFirstAlert",
            sortable: false,
            label: this.$t("dataTable.minutesFirstAlert.text"),
          },
          {
            key: "minutesBetweenAlerts",
            sortable: false,
            label: this.$t("dataTable.minutesBetweenAlerts.text"),
          },
          {
            key: "message",
            sortable: false,
            label: this.$t("dataTable.message.text"),
          },
          {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          },
        ];
      } else {
        return [
          {
            key: "device",
            sortable: false,
            label: "Serial",
          },
          {
            key: "condition",
            sortable: false,
            label: this.$t("dataTable.condition.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
          {
            key: "minutesFirstAlert",
            sortable: false,
            label: this.$t("dataTable.minutesFirstAlert.text"),
          },
          {
            key: "minutesBetweenAlerts",
            sortable: false,
            label: this.$t("dataTable.minutesBetweenAlerts.text"),
          },
          {
            key: "message",
            sortable: false,
            label: this.$t("dataTable.message.text"),
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...configAlertsMethods,
    showModalEdit() {
      this.$bvModal.show("edit-config-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-config-modal");
    },
    edit(config) {
      console.log(config);
      this.updateConfigAlert(config)
        .then(() => {
          this.makeToast("Update config", "config has been updated", "success");
        })
        .catch((error) => {
          this.makeToast("Update config", error, "danger");
        });
    },
    passDevice(config) {
      this.config = config;
      this.showModalEdit();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      this.$router.push({
        name: "device",
        params: { serial: record.serial },
      });
    },
  },
  watch: {
    configs(newVal) {
      this.configsList = [];
      newVal.map((obj) => {
        this.configsList.push({
          ...obj,
          device: obj.device.serial
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noConfigs.text')"
      :items="configsList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-inline-flex align-items-center">
          <b-button
            type="button"
            class="mr-2"
            variant="light"
            @click="passDevice(configs[data.index])"
            >{{ $t("buttons.edit.text") }}
          </b-button>
        </div>
      </template>
    </b-table>
    <EditModal
      v-bind:config="config"
      @editConfig="edit"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>