<template>
  <div>
    <b-modal
      id="edit-config-modal"
      ref="edit-config-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateConfig.text')"
    >
      <!-- value -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="value">{{ $t("modals.value.text") }}</label>
          <b-form-input
            id="value"
            v-model="configEdit.value"
            class="mb-1"
            :placeholder="$t('modals.value.text')"
            :state="err.value ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.value }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- minutesFirstAlert -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="minutesFirstAlert">{{
            $t("modals.minutesFirstAlert.text")
          }}</label>
          <b-form-input
            id="minutesFirstAlert"
            v-model="configEdit.minutesFirstAlert"
            class="mb-1"
            :placeholder="$t('modals.minutesFirstAlert.text')"
            :state="err.minutesFirstAlert ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.minutesFirstAlert }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- minutesBetweenAlerts -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="minutesBetweenAlerts">{{
            $t("modals.minutesBetweenAlerts.text")
          }}</label>
          <b-form-input
            id="minutesBetweenAlerts"
            v-model="configEdit.minutesBetweenAlerts"
            class="mb-1"
            :placeholder="$t('modals.minutesBetweenAlerts.text')"
            :state="err.minutesBetweenAlerts ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.minutesBetweenAlerts }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- message -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="message">{{ $t("modals.message.text") }}</label>
          <b-form-input
            id="message"
            v-model="configEdit.message"
            class="mb-1"
            :placeholder="$t('modals.message.text')"
            :state="err.message ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.message }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- condition -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="condition">{{ $t("modals.condition.text") }}</label>
          <b-form-group>
            <b-form-radio-group
              id="choice-condition"
              v-model="configEdit.condition"
              :options="conditionsOptions"
              name="choice-condition"
              buttons
              button-variant="light"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{ $t("buttons.update.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["config"],
  created() {},
  data() {
    return {
      conditionsOptions: [
        { text: "lt", value: "lt" },
        { text: "lte", value: "lte" },
        { text: "eq", value: "eq" },
        { text: "gte", value: "gte" },
        { text: "gt", value: "gt" },
      ],
      configEdit: {
        configAlertId: null,
        condition: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
      },
      err: {
        configAlertId: null,
        condition: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
      },
    };
  },
  watch: {
    config(newVal) {
      this.configEdit = {
        configAlertId: newVal.id,
        condition: newVal.condition,
        value: newVal.value,
        minutesFirstAlert: newVal.minutesFirstAlert,
        minutesBetweenAlerts: newVal.minutesBetweenAlerts,
        message: newVal.message,
      };
    },
  },
  methods: {
    cancel() {
      //this.clear();
    },
    clear() {
      (this.configEdit = {
        configAlertId: null,
        condition: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
      }),
        (this.err = {
          configAlertId: null,
          condition: null,
          value: null,
          minutesFirstAlert: null,
          minutesBetweenAlerts: null,
          message: null,
        });
    },
    edit(ok) {
      if (!this.configEdit.condition)
        this.err.condition = this.$t("msgError.obligField.text");
      else this.err.condition = "";
      if (!this.configEdit.value)
        this.err.value = this.$t("msgError.obligField.text");
      else this.err.value = "";
      if (!this.configEdit.minutesFirstAlert)
        this.err.minutesFirstAlert = this.$t("msgError.obligField.text");
      else this.err.minutesFirstAlert = "";
      if (!this.configEdit.minutesBetweenAlerts)
        this.err.minutesBetweenAlerts = this.$t("msgError.obligField.text");
      else this.err.minutesBetweenAlerts = "";
      if (!this.configEdit.message)
        this.err.message = this.$t("msgError.obligField.text");
      else this.err.message = "";

      if (
        !this.err.condition &&
        !this.err.value &&
        !this.err.minutesFirstAlert &&
        !this.err.minutesBetweenAlerts &&
        !this.err.message
      ) {
        if (
          this.configEdit.condition != this.config.condition ||
          this.configEdit.value != this.config.value ||
          this.configEdit.minutesFirstAlert != this.config.minutesFirstAlert ||
          this.configEdit.minutesBetweenAlerts != this.config.minutesBetweenAlerts ||
          this.configEdit.message != this.config.message
        ) {
          ok();
          this.$emit("editConfig", this.configEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>