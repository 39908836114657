<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfigsData from "./configs-data";
import AddConfig from "./add-config.vue"

import {
  layoutComputed,
  authComputed,
  configAlertsMethods,
  configsAlertsComputed,
} from "@/state/helpers";
/**
 * Configs component
 */
export default {
  page: {
    title: "Configs",
    meta: [{ name: "Configs" }],
  },
  components: {
    Layout,
    PageHeader,
    ConfigsData,
    AddConfig
  },
  mounted() {
    this.retrieveConfigs({});
  },
  data() {
    return {
      title: "titles.alertsconfigs.text",

      busy: true,

      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],

      loaderDashboard: false,

      configsData: [],

      configsFilter: {
        serial: null,
      },
      errFilter: null,
      searchStatus: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...configsAlertsComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.alertsconfigs.text"),
          active: true,
        },
      ];
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...configAlertsMethods,

    retrieveConfigs({ perPage, page, serial }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: "createdAt:desc",
        serial: serial ? serial : this.configsFilter.serial,
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getConfigAlertsBy(paramsFilter).then((configs) => {
        this.busy = false;
        return configs;
      });
    },
    addConfiguration(config){
      this.addConfigAlert(config)
        .then(() => {
          this.makeToast("Add config", "config has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add config", error, "danger")
        });
    },
    showModalAdd() {
      this.$bvModal.show("add-config-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-config-modal");
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    search() {
      if (!this.configsFilter.serial)
        this.errFilter = this.$t("msgError.obligField.text");
      else this.errFilter = "";
      if (!this.errFilter) {
        this.currentPage = 1;
        this.retrieveConfigs({ ...this.configsFilter, page: 1 }).then((configs) => {
          this.searchStatus = true;
          if (configs.results.length < 1) {
            this.makeToast("Search...", "Device not found !", "danger");
          } else {
            return;
          }
        });
      }
    },
    clearSearch() {
      this.currentPage = 1;
      (this.configsFilter = {
        serial: null,
      }),
        this.retrieveConfigs({ ...this.configsFilter, page: 1 }).then(() => {
          this.searchStatus = false;
        });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveConfigs({ ...this.configsFilter });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
  },
  watch: {
    getConfigAlerts(newVal) {
      this.configsData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="col-10 d-inline-flex align-items-center">
        <b-form-input
          id="stateSh"
          v-model="configsFilter.serial"
          :placeholder="$t('textInput.enterSerial.text')"
          class="mr-2 w-50"
          :state="errFilter ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback style="position: absolute; top: 80%">
          {{ errFilter }}
        </b-form-invalid-feedback>
        <b-button type="button" class="mr-2" variant="secondary" @click="search"
          >{{ $t("buttons.search.text") }}
        </b-button>
        <b-button
          v-if="searchStatus"
          type="button"
          class="mr-2"
          variant="warning"
          @click="clearSearch"
          ><i class="ri-refresh-line"></i>
        </b-button>
      </div>
      <div class="col-2 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="showModalAdd"
          >{{$t("buttons.addConfig.text")}}
        </b-button>
        <AddConfig @addConfig="addConfiguration" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ConfigsData
          v-bind:configs="configsData"
          :busy="busy"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getConfigAlerts.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>