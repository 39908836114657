<template>
  <div>
    <b-modal
      id="add-config-modal"
      ref="add-config-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addConfig.text')"
    >
      <!-- serial -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="serial">{{ $t("modals.serial.text") }}</label>
          <b-form-input
            id="serial"
            v-model="configAdd.serial"
            class="mb-1"
            :placeholder="$t('modals.serial.text')"
            :state="err.serial ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.serial }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- value -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="value">{{ $t("modals.value.text") }}</label>
          <b-form-input
            id="value"
            v-model="configAdd.value"
            class="mb-1"
            :placeholder="$t('modals.value.text')"
            :state="err.value ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.value }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- minutesFirstAlert -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="minutesFirstAlert">{{
            $t("modals.minutesFirstAlert.text")
          }}</label>
          <b-form-input
            id="minutesFirstAlert"
            v-model="configAdd.minutesFirstAlert"
            class="mb-1"
            :placeholder="$t('modals.minutesFirstAlert.text')"
            :state="err.minutesFirstAlert ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.minutesFirstAlert }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- minutesBetweenAlerts -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="minutesBetweenAlerts">{{
            $t("modals.minutesBetweenAlerts.text")
          }}</label>
          <b-form-input
            id="minutesBetweenAlerts"
            v-model="configAdd.minutesBetweenAlerts"
            class="mb-1"
            :placeholder="$t('modals.minutesBetweenAlerts.text')"
            :state="err.minutesBetweenAlerts ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.minutesBetweenAlerts }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- message -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="message">{{ $t("modals.message.text") }}</label>
          <b-form-input
            id="message"
            v-model="configAdd.message"
            class="mb-1"
            :placeholder="$t('modals.message.text')"
            :state="err.message ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.message }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- condition -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="condition">{{ $t("modals.condition.text") }}</label>
          <b-form-group>
            <b-form-radio-group
              id="choice-condition"
              v-model="configAdd.condition"
              :options="conditionsOptions"
              name="choice-condition"
              buttons
              button-variant="light"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{ $t("buttons.add.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      conditionsOptions: [
        { text: "lt", value: "lt" },
        { text: "lte", value: "lte" },
        { text: "eq", value: "eq" },
        { text: "gte", value: "gte" },
        { text: "gt", value: "gt" },
      ],
      configAdd: {
        serial: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
        condition: null,
      },
      err: {
        serial: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
        condition: null,
      },
    };
  },
  watch: {},
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      this.configAdd = {
        serial: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
        condition: null,
      };
      this.err = {
        serial: null,
        value: null,
        minutesFirstAlert: null,
        minutesBetweenAlerts: null,
        message: null,
        condition: null,
      };
    },
    add(ok) {
      if (!this.configAdd.serial)
        this.err.serial = this.$t("msgError.obligField.text");
      else this.err.serial = "";
      if (!this.configAdd.condition)
        this.err.condition = this.$t("msgError.obligField.text");
      else this.err.condition = "";
      if (!this.configAdd.value)
        this.err.value = this.$t("msgError.obligField.text");
      else this.err.value = "";
      if (!this.configAdd.minutesFirstAlert)
        this.err.minutesFirstAlert = this.$t("msgError.obligField.text");
      else this.err.minutesFirstAlert = "";
      if (!this.configAdd.minutesBetweenAlerts)
        this.err.minutesBetweenAlerts = this.$t("msgError.obligField.text");
      else this.err.minutesBetweenAlerts = "";
      if (!this.configAdd.message)
        this.err.message = this.$t("msgError.obligField.text");
      else this.err.message = "";

      if (
        !this.err.serial &&
        !this.err.condition &&
        !this.err.value &&
        !this.err.minutesFirstAlert &&
        !this.err.minutesBetweenAlerts &&
        !this.err.message
      ) {
        ok();
        this.$emit("addConfig", this.configAdd);
      }
    },
  },
};
</script>